import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import AskMufti from "./Pages/askMufti/AskMufti";
import Services from "./Pages/services/Services";
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/contactus/ContactUs';
import Pricing from "./Pages/Packages/Pricing";
import Crypterium from './Pages/crypto/Crypterium'
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={<div>404 Not Found</div>}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/askmufti" element={<AskMufti />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/aboutus" element={<AboutUs />}></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
            <Route path="/cryptocard" element={<Crypterium />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
