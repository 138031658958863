
export default function MissionVission() {
    return (
      <section className="skills--section" id="mySkills">
        
        {/* <div className='cardgrid2'>
        <div class="card">
        <div class="card__img">  
          <img src="./img/teaching2.jpg"/>

          </div>
        </div>
            <div class="card">
        <div class="card__img">  
          <img src="./img/teaching1.jpg"/>

          </div>
        </div>


            <div class="card1">
        <div class="card__img">  
        <div className="hero--section--content">
          <h2 className="femaletuitor--section--heading">Mission Statement</h2>
            <p className="hero--section-description">Use this space to talk about the different components of your company's branding. You can feature the logo, brand colors, typography, or any other elements related to your brand. </p>
       
        </div>
          
          </div>
        </div>

        <div class="card1">
        <div class="card__img">  
        <div className="hero--section--content">
          <h2 className="femaletuitor--section--heading">Vission Statement</h2>
            <p className="hero--section-description">Use this space to talk about the different components of your company's branding. You can feature the logo, brand colors, typography, or any other elements related to your brand. </p>
       
        </div>
          
          </div>
        </div>
        
            <div class="card">
        <div class="card__img">  
          <img src="./img/teaching4.jpg"/>
 
          </div>
        </div>
       

      

        </div> */}
          <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>
            <img src='./img/teaching1.jpg' alt='' />
          </div>
          <div className='right row'>
         <h1></h1>
            <div className='items'>

                  <div className='item flexSB'>
                    <div className='img'>
                      <img src="https://img.icons8.com/dotty/80/000000/storytelling.png" alt='' />
                    </div>
                    <div className='text'>
                      <h2>Mission Statement</h2>
                      <p>Empowering hearts and minds through the divine light of the Quran, Roshan Quran is dedicated to fostering a deep and meaningful connection with the sacred scripture. Our mission is to illuminate the path of spiritual enlightenment, providing accessible and enriching resources that inspire understanding, reflection, and application of the timeless wisdom contained within the Quran. </p>
                    </div>
                  </div>

                  <div className='item flexSB'>
                    <div className='img'>
                      <img src="https://img.icons8.com/ios/80/000000/diploma.png" alt='' />
                    </div>
                    <div className='text'>
                      <h2>Vission Statement</h2>
                      <p>Roshan Quran envisions a world where the light of the Quran shines brightly in every heart and every home, guiding humanity towards deeper spiritual fulfillment and harmony. We aspire to be the leading catalyst for this vision, offering innovative and inclusive platforms that illuminate minds, nurture souls, and foster a global community united by the timeless wisdom of the Quran.</p>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </section>
      
  
      </section>
    );
  }
  