import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import AboutHero from "./AboutHero";
import AboutRoshan from "./AboutRoshan";
import AboutTeam from "./AboutTeam";
import Testimonial from "../Home/Testimonials";
import MissionVission from "./MissionVission";
import BlogCard from "../Home/BlogCard";
import AboutReg from "./AboutReg";
export default function AskMufti() {
    return (
        <>
          <Navbar/>
          <AboutHero/>
          <AboutReg/>
          <AboutRoshan/>
          <AboutTeam/>
          {/* <ContactMufti/>
          <SelectMufti/> */}
          <MissionVission/>
          <BlogCard/>
          <Testimonial/>
          <Footer/>
        </>
      );
}