export default function Cryptohero() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Cryptocurrency <br /> Platform   
  </span>{" "}
              
            </h1>
            <p className="hero--section-description">
            Cutting edge crypto payment solution for all e-commerce platforms and e-shops with integrated major cryptocurrencies. GLOBAL PAYMENT 
            </p>
          </div>
          {/* <div className="herobtn">
          <button className="btn btn-primary">Ask Mufti</button>
          </div> */}
        </div>
        <div className="hero--section--img">
          <img src="./img/dgdv-01.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  