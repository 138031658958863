export default function ContactMufti() {
    return (
      <section id="Contact" className="contact--section">
        <div>
          <h2 className="hero--section--title">Contact Mufti</h2>
          
        </div>
        <form className="contact--form--container">
          <div className="container">
            <label htmlFor="first-name" className="contact--label">
              <span className="text-md">Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="first-name"
                id="first-name"
                required
              />
            </label>
           
            <label htmlFor="email" className="contact--label">
              <span className="text-md">Email</span>
              <input
                type="email"
                className="contact--input text-md"
                name="email"
                id="email"
                required
              />
            </label>
            <label htmlFor="phone-number" className="contact--label">
              <span className="text-md">Phone Number</span>
              <input
                type="number"
                className="contact--input text-md"
                name="phone-number"
                id="phone-number"
                required
              />
            </label>
          <label htmlFor="choode-topic" className="contact--label">
            <span className="text-md">Mode of Communication</span>
            <select id="choose-topic" className="contact--input text-md">
              <option>Audio Call</option>
              <option>Video Call</option>
              <option>Email</option>
              <option>Meet in person</option>
            </select>
          </label>
          <label htmlFor="choode-topic" className="contact--label">
            <span className="text-md">Prefferd Language</span>
            <select id="choose-topic" className="contact--input text-md">
              <option>English</option>
              <option>Urdu</option>
              <option>Arabic</option>
            </select>
          </label>
          <label htmlFor="first-name" className="contact--label">
              <span className="text-md">Subject</span>
              <input
                type="text"
                className="contact--input text-md"
                name="first-name"
                id="first-name"
                required
              />
            </label>
          </div>
          <label htmlFor="message" className="contact--label">
            <span className="text-md">Message</span>
            <textarea
              className="contact--input text-md"
              id="message"
              rows="8"
              placeholder="Type your message..."
            />
          </label>
          {/* <label htmlFor="checkboc" className="checkbox--label">
            <input type="checkbox" required name="checkbox" id="checkbox" />
            <span className="text-sm">I accept the terms</span>
          </label> */}
          <div>
            <button className="btn btn-primary contact--form--btn">Submit</button>
          </div>
        </form>
      </section>
    );
  }
  