// import data from "../../data/index.json";
// export default function MaleTuitor() {
//   return (
//     <section className="skills--section" id="mySkills">
//       <div className="portfolio--container">
//         <h2 className="skills--section--heading sub--title">Featured Courses</h2>
//       </div>
//       <div className="skills--section--container">
//         {data?.courses?.map((item, index) => (
//           <div key={index} className="skills--section--card">
            
//             <div className="course--section--img">
//               <img className='coursepic' src={item.src} alt="Product Chain" />
//             </div>
           
//             <div className="skills--section--card--content">
//               <h3 className="skills--section--title">{item.title}</h3>
//               <p className="skills--section--description">{item.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }


import { Link } from 'react-router-dom';
import data from "../../data/index.json";

export default function MaleTuitor() {
  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Featured Courses</h2>
      </div>
      <div className="skills--section--container">
        {data?.courses?.map((item, index) => (
          <div key={index} className="skills--section--card">
            <Link to={item.route}> {/* Add Link with route */}
              <div className="course--section--img">
                <img className='coursepic' src={item.src} alt="Product Chain" />
              </div>
            </Link>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}