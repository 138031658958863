

export default function Platform() {
    return (
      <section id="AboutMe" className="about--section">
        
        <div className="about--section--img">
          <img src="./img/pic1_Mesa de trabajo 1.png" alt="About Me" />
        </div>

        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            {/* <p className="section--title">About</p> */}
            <h1 className="skills--section--heading">A banking  
            <span style={{fontSize: 20}}> platform developer solutions</span></h1>
            <p className="hero--section-description">
            Spend real fights effective anything extra by leading. Mouthwatering leading how
             real formula also locked-in have can mountain thought. Jumbo plus shine sale.
            </p>
            <p className="hero--section-description">
            <span tyle={{ fontWeight: "underline" }}>Modular structure</span> enabling easy implementation for different softwares
            </p>
            <p className="hero--section-description">
            <span tyle={{ fontWeight: "underline" }}>Advanced payment</span> and processing technologies, fine-tuned from more than 3 years of development testing
            </p>
            {/* <p className="hero--section-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
              officiis sit debitis omnis harum sed veniam quasi dicta accusamus
              recusandae?
            </p> */}
          </div>
        </div>
  
      </section>
    );
  }
  