import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import ContactMufti from "./ContactMufti";
import MuftiHero from "./MuftiHero";
import SelectMufti from "./SelectMufti";
import BlogCard from "../Home/BlogCard";
import Roshanquranlearn from "./Roshanquranlearn";
import Whatyoulearn from "./Whatyoulearn";

export default function AskMufti() {
    return (
        <>
          <Navbar/>
          <MuftiHero/>
          <Roshanquranlearn/>
          <Whatyoulearn/>
          <ContactMufti/>
          <SelectMufti/>
          <BlogCard/>
          <Footer/>
        </>
      );
}