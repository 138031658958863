export default function AboutHero() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Our Story 
  </span>{" "}
              
            </h1>
            <p className="hero--section-description">
            We aim to make every Muslim understand and follow the basic principles of our religion so as to ensure we can become a better Ummah as well as make this world a better place for everyone to live in.
            </p>
          </div>
          <div className="herobtn">
          <button className="btn btn-primary">Read More</button>
          </div>
        </div>
        <div className="hero--section--img">
          <img src="./img/qur.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  