export default function AboutRoshan() {
    return (
      <>
      <section className="aboutroshan--section" id="mySkills">
          <h2 className="skills--section--heading sub--title">About Roshan Quran</h2>
        </section>
 <section id="AboutMe" className="aboutroshan1--section">
      
 <div className="hero--section--content--box about--section--box">
   <div className="hero--section--content">
     {/* <p className="section--title">About</p> */}
     <p className="hero--section-description">
     Roshan Quran is an independent entity managed by a small group
      of dedicated professionals. We welcome everyone to learn the teachings
       of the holy Quran and the commandments of Islam irrespective of their
        race, colour, or cast. We aim to make every Muslim understand and follow
         the basic principles of our religion so as to ensure we can become a better
          Ummah as well as make this world a better place for everyone to live in.
     </p>
     {/* <p className="hero--section-description">
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
       officiis sit debitis omnis harum sed veniam quasi dicta accusamus
       recusandae?
     </p> */}
   </div>
 </div>
 <div className="about--section--img">
   <img src="./img/about1.png" alt="About Me" />
 </div>


 <div className="about--section--img">
   <img src="./img/about2.png" alt="About Me" />
 </div>
 <div className="hero--section--content--box about--section--box">
   <div className="hero--section--content">
     {/* <p className="section--title">About</p> */}
     <p className="hero--section-description">
     Keeping these things in mind, we have put in special efforts in 
     to creating our online Quranic classes so that everyone can take
      part in them at a time that suits them best. You can start your
       online Quran course from the comfort of your home, pick a timetable
        that suits you best, and begin this journey of learning the holy Quran right from the basics.
     </p>
     {/* <p className="hero--section-description">
       Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
       officiis sit debitis omnis harum sed veniam quasi dicta accusamus
       recusandae?
     </p> */}
   </div>
 </div>

</section>
   
</>
   );
 }
 