import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import Cryptohero from "../crypto/Cryptohero"
import Platform from "./Platform";
import Dynamicplatform from "./Dynamicplatform";
import Timeline from "./Timeline";
import Itwork from "./Itwork";

export default function AskMufti() {
    return (
        <>
          <Navbar/>
           <Cryptohero/>
           <Platform/>
           <Dynamicplatform/>
           <Timeline/>
           <Itwork/>
          <Footer/>
        </>
      );
}