import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import Pricingcard from "../Packages/Pricingcard"
import BlogCard from "../Home/BlogCard";
import Prichero from "./Prichero";

export default function Pricing() {
    return (
        <>
          <Navbar/>
          <Prichero/>
           <Pricingcard/>
          <BlogCard/>
          <Footer/>
        </>
      );
}