export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          {/* <p className="section--title">About</p> */}
          <h1 className="skills--section--heading">Significance of The Holy Quran</h1>
          <p className="hero--section-description">
          The Holy Quran is the book of Allah revealed to Prophet Muhammad (P.B.U.H)
           fourteen hundred years ago. Not even a dot has been displaced in this book since then.
            It is Allah’s final and the last book of guidance for mankind. The direction of the
             true path is set in this book. Mankind needs to understand the message of the Holy Quran
              correctly. We must follow its message If we want happiness in this world and the hereafter.
          </p>
          {/* <p className="hero--section-description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
            officiis sit debitis omnis harum sed veniam quasi dicta accusamus
            recusandae?
          </p> */}
        </div>
      </div>
      <div className="about--section--img">
        <img src="./img/about.png" alt="About Me" />
      </div>

    </section>
  );
}
