import data from "../../data/index.json";
import Accordion from "../askMufti/Accordion";
export default function AboutTeam() {
  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Meet the Team</h2>
      </div>
      <div className="aboutteam--section--container">
        {data?.team?.map((item, index) => (
          <div key={index} className="aboutteam--section--card">
            <div className="maletuitor--section--img">
              <img className='malepic' src={item.src} alt="Product Chain" />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Accordion/>
    </section>
  );
}