export default function MuftiHero() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Consultation <br /> Services   
  </span>{" "}
              
            </h1>
            <p className="hero--section-description">
            Communication is crucial for online Quran classes. You should ensure that you understand 
              <br /> the instructions given by your teacher and clarify any confusion.
            </p>
          </div>
          <div className="herobtn">
          <button className="btn btn-primary">Ask Mufti</button>
          </div>
        </div>
        <div className="hero--section--img">
          <img src="./img/muf.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  