import React, { useRef, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import data from "../../data/index.json";

const AccordionItem = ({ question, answer, answer1, answer2, answer3, isOpen, onClick }) => {
    const contentHeight = useRef()
     return(
       <div className="wrapper" >
       <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
        <p className='question-content'>{question}</p>
        <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} /> 
       </button>
   
        <div ref={contentHeight} className="answer-container" style={
             isOpen
             ? { height: contentHeight.current.scrollHeight }
             : { height: "0px" }
            }>
         <p className="answer-content">{answer}</p>
         <p className="answer-content">{answer1}</p>
         <p className="answer-content">{answer2}</p>
         <p className="answer-content">{answer3}</p>
        </div>
      </div>
     )
   }
  
  // main Accordion component
  const Whatyoulearn = () => {
 const [activeIndex, setActiveIndex] = useState(null);

 const handleItemClick = (index) => {
  setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
 };
    return (
        <>
        <section className="skills--section" id="mySkills">
        <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">What You'll Learn</h2>
      </div>
      </section>
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
          {data?.whatlearn?.map((item, index) => (
    <AccordionItem
     key={index}
     question={item.question}
     answer={item.answer}
     answer1={item.answer1}
     answer2={item.answer2}
     answer3={item.answer3}
     isOpen={activeIndex === index}
     onClick={() => handleItemClick(index)}
    />
   ))}
         
          </div>
        </div>
        <div className="about--section--img">
          <img src="./img/hugv.jpg" alt="About Me" />
        </div>
  
      </section>
      </>
    );
  }
  export default Whatyoulearn;