export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Islamic education based 
on decentralization   
</span>{" "}
            
          </h1>
          <p className="hero--section-description">
          “Read! In the name of your Lord, who has created (all that exists)” (Al Quran)<br/>
          “Empowering Minds, Nurturing Souls: Your Journey with the Quran Begins Here.”
          </p>
        </div>
        <div className="herobtn">
        <button className="btn btn-primary">Start Learning</button>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section" />
      </div>
    </section>
  );
}
