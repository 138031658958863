import data from "../../data/index.json";
export default function Roshanquranlearn() {
    return (
        <>
         <section className="skills--section" id="mySkills">
        <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Roshanquran Academy Offers</h2>
        <p className="hero--section-description">Roshanquran Academy has different Quranic and Islamic courses on offer
         depending upon your requirements. Roshanquran Academy has got expert Quran teachers, both male and female
          for women and children, with fluency in the English language. These highly qualified Online Quran
           Tutors in UK take their students step by step to learn tajweed Quran word by word. We understand that
            not everyone has the same learning capability. Therefore, our Quran teachers work at a pace that matches
             each and every one of our student’s learning ability, enabling them to learn the recitation of Quran 
             comprehensively.</p>
      </div>
      </section>
      <section id="AboutMe" className="about--section">
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            {/* <p className="section--title">About</p> */}
            {/* <h1 className="skills--section--heading">Learn from Professionals</h1> */}
            <p className="hero--section-description">
            Keeping the needs of our Muslim brothers and sisters in mind, Roshanquran Academy has
             designed virtual Quran courses to ensure everyone can learn the teachings of the holy Quran
              from the comfort of their home. You can choose a time from your daily routine that suits
               you best and take the class wherever you are. To make our sessions interactive, 
               we make use of technology and conduct our online Quran teaching sessions through Zoom or Skype.
                In this way, both children and women don’t have to go to Islamic centers on a regular basis to
                 learn Quran. Have a look at the courses we offer and start taking classes virtually from anywhere
                  with our Online Quran Tutors in UK
            </p>
            {/* <p className="hero--section-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
              officiis sit debitis omnis harum sed veniam quasi dicta accusamus
              recusandae?
            </p> */}
          </div>
        </div>
        <div className="about--section--img">
          <img src="./img/online-quran-classes-skype.jpg" alt="About Me" />
        </div>
  
      </section>
      <section className="concept--section" id="mySkills">
      <div className="aboutteam--section--container">
        {data?.concepts?.map((item, index) => (
          <div key={index} className="aboutteam--section--card">
            <div className="maletuitor--section--img">
              <img className='malepic' src={item.src} alt="Product Chain" />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
      </>
    );
  }
  