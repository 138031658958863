import AboutMe from "../AboutMe";
// import ContactMe from "../ContactMe";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
// import MyPortfolio from "../MyPortfolio";
import FemaleTuitor from "../FemaleTuitor";
import MaleTuitor from "../MaleTuitor";
import MySkills from "../MySkills";
import Testimonial from "../Testimonials";
import Featurestcourse from "../Featurestcourse"
import BlogCard from "../BlogCard";
import Whychoose from "../Whychoose";
export default function Home() {
  return (
    <>
      <HeroSection />
      <MySkills />
      <AboutMe />
      <Featurestcourse/>
      <FemaleTuitor />
      <MaleTuitor/>
      <Whychoose/>
      <BlogCard/>

      {/* <MyPortfolio /> */}
      <Testimonial />
      {/* <ContactMe /> */}
      <Footer />
    </>
  );
}
