
export default function AboutReg() {
  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Begin Learning Quran Now in 3 Easy Steps</h2>
        <p className="hero--section-description">Getting yourself or your loved ones familiarized with the teachings of the holy Quran doesn’t have to be a hassle. This is the reason why Quran Learn Academy has developed an extremely easy to understand online registration process you can go through to get yourself or your children registered. Just follow these steps to get things on the way.</p>
      </div>
      <div class="plan__grid">
                <div class="plan__content">
                  <span class="number">01</span>
                  <h2>Click on Register Online</h2> 
                  <p >
                  Simply click on “Register Online” and a tab for online registration will open. There,
                   enter your basic details such as name, residential address, and contact information.
                    Once you are done with providing us theses basic credentials, your process for registration
                     will be complete.
                  </p>
                  <span class="number">02</span>
                  <h2>Pick a Time for Free Trial:</h2> 
                  <p >
                  Upon receiving your request for registration, we will get in touch with you to set a time as to when
                   can you take your free trial class. In addition, we will guide you about our Quran teaching process
                    and tell you a little bit about our organization.
                  </p>
                   <span class="number">03</span>
                  <h2>Embark On this Holy Journey with your 1st Class:</h2>
                  <p >
                  Initiate your Quran learning process by logging in with the credentials we provide you and
                   start taking your first class with our Quran teachers.
                  </p> 
                </div>
                <div class="plan__image">
                  <img src="./img/ghh.jpg" alt="plan" />
                  <img src="./img/gv.jpg" alt="plan" />
                  <img src="./img/teaching1.jpg" alt="plan" />
                </div>
              </div>
    
    </section>
  );
}