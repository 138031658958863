
import Keyfeature from './Keyfeature';
import Serviceherosection from "./Serviceherosection";
import Classessch from "./Classessch";

import Footer from '../Home/Footer'
import Featurestcourse from '../Home/Featurestcourse'
import BlogCard from '../Home/BlogCard';
export default function Services() {
  return (
    <>
      <Serviceherosection/>
      <Keyfeature/>
      {/* <Classroom/> */}
      <Featurestcourse/>
      <Classessch/>
      {/* <AboutMe /> */}
      {/* <ContactMe /> */}
      <BlogCard/>
      <Footer />
    </>
  );
}
