// import AboutMe from "../AboutMe";
// import ContactMe from "../ContactMe";
// import Footer from "../Footer";
import ContactForm from '../contactus/ContactForm'
export default function ContactUs() {
  return (
    <>
    <ContactForm/>
    </>
  );
}
