import data from "../../data/index.json";

export default function SelectMufti() {
  return (
    <section className="price--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Pricing & Packages</h2>
      </div>
      <div className="skills--section--container">
        {data?.price?.map((item, index) => (
          <div key={index} className="skills--section--card">
            <section className='price padding'>
        <div className='container grid'>
        <div className='items shadow'>
          <h2>{item.name}</h2>
          <br/>
          <h4>{item.name1}</h4>
          <h4>{item.name2}</h4>
          <h1>
            <span>$</span>
            {item.price}
          </h1>
          <p>✔ &nbsp;{item.desc}</p>
          <p>✔ &nbsp;{item.desc1}</p>
          <p>✔ &nbsp;{item.desc2}</p>
          <p>✔ &nbsp;{item.desc3}</p>
          <button className='outline-btn'>GET STARTED</button>
        </div>
        </div>
      </section>

            {/* <div className="two-flex">
                <div className="img-flex">
                <img src={item.src} alt="Product Chain" />
                <h3>{item.name}</h3>
                </div>

                <div className="spec-flex">
                <h3>Specialization</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati fuga iure architecto explicabo sed distinctio iusto ea? Deleniti, error veniam.</p>
                </div>
            </div> */}

            {/* <div className="one-flex">
              <h3 className="">Consultation Timings</h3>
              <p className="">{item.timings}</p>
              <button>Select</button>
            </div> */}
          </div>
        ))}
      </div>

     </section>

     
  );
}
