import data from "../../data/index.json";

export default function BlogCard() {
  return (
    <section className="skills--section" id="mySkills">
        <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">News & Updates</h2>
      </div>
        <div className="blog--section">
     <div className="blog-card">
      <div className="meta">
        <div className="photo" style={{backgroundImage: `url("./img/22.jpg")`}}></div>
      </div>
      <div className="description">
        <h1>Virtual Learning of Quran In Non-Islamic Countries</h1>
        <h2>04 Feb, 2022</h2>
        <p>Knowledge is a huge part of the religion Islam or any religion for that matter. The Holy Book and the teachings of the Last Prophet motivate us to gain knowledge and to use it. This is important for not only Muslims but for human beings as well to be able to progress.</p>
       
      </div>
    </div>
    <div className="blog-card">
      <div className="meta">
        <div className="photo" style={{backgroundImage: `url("./img/45.jpg")`}}></div>
      </div>
      <div className="description">
        <h1>Online Quran learning help Muslims in non-Islamic countries?</h1>
        <h2>04 Feb, 2022</h2>
        <p>Learning and reading Quran once in a lifetime is obligatory for every Muslim individual. In Pakistan, most people send their kids to Madrasas to learn this sacred book. But learning Quran abroad is very difficult, there is no concept of Islamic schools there and no teachers to teach. </p>
       
      </div>
    </div>
    <div className="blog-card">
      <div className="meta">
        <div className="photo" style={{backgroundImage: `url("./img/4654.jpg")`}}></div>
      </div>
      <div className="description">
        <h1>Make your Quran learning stages easier through online learning</h1>
        <h2>04 Feb, 2022</h2>
        <p>Quran is being taught in many non-Islamic countries with the help of technology. Learn Quran Academy is an online platform that leads the Quran on digital media. This has been done by using audio recordings and translations on the website.</p>
       
      </div>
    </div>
    <div className="blog-card">
      <div className="meta">
        <div className="photo" style={{backgroundImage: `url("./img/123.png")`}}></div>
      </div>
      <div className="description">
        <h1>Learn Quran academy- A modern way to Learn Quran</h1>
        <h2>04 Feb, 2022</h2>
        <p>Quran is the holy book of Islam. It is written in Arabic and is the revealed word of Allah (God) to the prophet Muhammad. Quran consists of 114 chapters that are each subdivided into verses. Muslims believe that Quran contains details. </p>
       
      </div>
    </div>
    </div>
    </section>
  );
}