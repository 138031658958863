
export default function FemaleTuitor() {
    return (
      <section className="female--section" id="mySkills">
          <div className="portfolio--container-box">
        <div className='cardgrid1'>
        <div class="card">
        <div class="card__img">  
          <img src="./img/teaching2.jpg"/>

          </div>
        </div>
            <div class="card">
        <div class="card__img">  
          <img src="./img/teaching1.jpg"/>

          </div>
        </div>


            <div class="card1">
        <div class="card__img">  
        <div className="hero--section--content">
          <h2 className="femaletuitor--section--heading">Female Tutors</h2>
            <p className="hero--section-description">We have a mission of providing Quran education online to everyone. Be it male or female, our online Quran academy has tutors for both. We also have female tutors who help you learn the Holy Quran. </p>
       
        </div>
          
          </div>
        </div>
        
            <div class="card">
        <div class="card__img">  
          <img src="./img/teaching4.jpg"/>
 
          </div>
        </div>
            {/* <div class="card">
        <div class="card__img">  
          <img src="./img/teaching3.jpg" alt=""/>
             
          </div>
        </div> */}
            <div class="card" >
        <div class="card__img" >  
          <img src="./img/teaching5.jpg" className="femaleimg" 
          />

          </div>
        </div>

        </div>
        </div>
  
      </section>
    );
  }
  