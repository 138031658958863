export default function Serviceherosection() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Quran Classes
  </span>{" "}
              
            </h1>
            <p className="hero--section-description">
            We are appreciative of the countless virtual resources, organized virtual learning system, small class sizes, and shorter instructional hours. 
            </p>
          </div>
          <div className="herobtn">
          <button className="btn btn-primary">Learn More</button>
          </div>
        </div>
        <div className="hero--section--img">
          <img src="./img/servicemain.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  