//  accordionitem component

import React, { useRef, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import data from "../../data/index.json";

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
    const contentHeight = useRef()
     return(
       <div className="wrapper" >
       <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
        <p className='question-content'>{question}</p>
        <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} /> 
       </button>
   
        <div ref={contentHeight} className="answer-container" style={
             isOpen
             ? { height: contentHeight.current.scrollHeight }
             : { height: "0px" }
            }>
         <p className="answer-content">{answer}</p>
        </div>
      </div>
     )
   }
  
  // main Accordion component
  const Accordion = () => {
 const [activeIndex, setActiveIndex] = useState(null);

 const handleItemClick = (index) => {
  setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
 };

 return (
  
  // <section className="skills--section" id="mySkills">
  <div className="">
    <br />
       <h2 className="skills--section--heading sub--title">   <h2 className="skills--section--heading sub--title">Frequently Asked Questions (FAQs)</h2>
      </h2>
      <br />

    {data?.accordions?.map((item, index) => (
    <AccordionItem
     key={index}
     question={item.question}
     answer={item.answer}
     isOpen={activeIndex === index}
     onClick={() => handleItemClick(index)}
    />
   ))}
  </div>
  // </section>
 )
};

export default Accordion;