export default function Prichero() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Consultation <br /> Packages   
  </span>{" "}
              
            </h1>
            <p className="hero--section-description">
            Online Quran academy has been enlightening Muslims about the teachings of the holy Quran and making their lives according to the principles of Islam.
            </p>
          </div>
          {/* <button className="btn btn-primary">Ask Mufti</button> */}
        </div>
        <div className="hero--section--img">
          <img src="./img/pricebanner.png" alt="Hero Section" />
        </div>
      </section>
    );
  }
  