
import "../crypto/Timeline.css"

export default function Timeline() {
    return (
        <section className="skills--section" id="mySkills">
      <div className="timelinealign">
         <h1 style={{textAlign:"center", color: "#006b6a"}}>Why it needs?</h1>
      <div className="first">
        <li style={{ '--accent-color': '#D3B45B' }}>
          <div className="date">Mining Service</div>
          <div className="title"></div>
          <div className="descr">Spend real fights effective anything extra by leading. Mouthwatering leading how real formula also locked-in have can mountain thought. Jumbo plus shine sale.</div>
        </li>
        <li style={{ '--accent-color': '#4CADAD' }}>
          <div className="date">Exchange</div>
          <div className="title"></div>
          <div className="descr">Barbelless catfish pelican gulper candlefish thornfishGulf menhaden ribbonbearer riffle dace black dragonfish denticle herring ewel tetra frigate mackerel gombessa prickly shark.</div>
        </li>
        <li style={{ '--accent-color': '#D3B45B' }}>
          <div className="date">Blockchain</div>
          <div className="title"></div>
          <div className="descr">Clownfish catfish antenna codlet alfonsino squirrelfish deepwater flathead sea lamprey. Bombay duck sand goby snake mudhead mahi-mahi pygmy sunfish beachsalmon.</div>
        </li>
        <li style={{ '--accent-color': '#4CADAD' }}>
          <div className="date">Mouthwaterin</div>
          <div className="title"></div>
          <div className="descr">Spend real fights effective anything extra by leading. Mouthwatering leading how real formula also locked-in have can mountain thought. Jumbo plus shine sale.</div>
        </li>

      </div>
      </div>
      </section>
    );
  }
  