import data from "../../data/index.json";

export default function Whychoose() {
    return (
    <>
        <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Why Choose Us</h2>
      </div>
      <div className="skills--section--container">
        {data?.whychooses?.map((item, index) => (
          <div key={index} className="choose--section--card">
            <div className="course--section--img">
              <img className='coursepic' src={item.src} alt="Product Chain" />
            </div>
            <div className="skills--section--card--content">
              <h3 className="choose--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    </>
    );
  }
  