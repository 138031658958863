import data from "../../data/index.json";
import Accordion from "./Accordion";

export default function SelectMufti() {
  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h2 className="skills--section--heading sub--title">Select Mufti</h2>
      </div>
      <div className="skills--section--container">
        {data?.selectMufti?.map((item, index) => (
          <div key={index} className="skills--section--card">


            <div className="two-flex">
                <div className="img-flex">
                <img src={item.src} alt="Product Chain" />
                <h3>{item.name}</h3>
                </div>

                <div className="spec-flex">
                <h3>Specialization</h3>
                <p>{item.detail}</p>
                </div>
            </div>

            <div className="one-flex">
              <h3 className="">Consultation Timings</h3>
              <p className="">{item.timings}</p>
              {/* <button>Select</button> */}
            </div>
          </div>
        ))}
      </div>

<br></br>
      <h2 className="skills--section--heading sub--title">Select Date & Time</h2>
 
      <div className="container">
  <input type="datetime-local"
  className="contact--input text-md"
   name="" id="" />

<button className="btn btn-primary">Submit</button>

     </div>
     <Accordion/>
     </section>

     
  );
}
